import React, { useState, useEffect } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { gitLabApiRef } from './GitlabIssuesApi';
import { Progress, Table, TableColumn } from '@backstage/core-components';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  Button,
  Link,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import dayjs from 'dayjs';

type GitLabIssue = {
  id: number;
  title: string;
  description: string;
  state: string;
  created_at: string;
  due_date: string | null;
  milestone?: { due_date: string | null };
  assignees: {
    id: number;
    username: string;
    name: string;
    avatar_url: string;
    web_url: string;
  }[];
  web_url: string;
  labels: string[];
  project_path: string;
};

export const GitLabAssignedIssuesComponent = ({
  user,
  projectName,
}: {
  user?: string;
  projectName?: string;
}) => {
  const { entity } = useEntity();
  const gitLabApi = useApi(gitLabApiRef);
  const errorApi = useApi(errorApiRef);

  const [issues, setIssues] = useState<GitLabIssue[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState<GitLabIssue | null>(null);
  const [showClosed, setShowClosed] = useState(false);

  useEffect(() => {
    const fetchIssues = async () => {
      setLoading(true);
      try {
        // Get projectSlug from entity annotations
        const entitySlug = entity.metadata.annotations?.['gitlab.com/project-slug'];
        if (!entitySlug) {
          // No projectSlug means no issues to fetch
          setIssues([]);
          setLoading(false);
          return;
        }

        const normalizeSlug = (slug: string) => slug.replace(/\//g, '-').toLowerCase();

        // Normalize projectSlug
        const projectSlug = normalizeSlug(entitySlug);

        // Derive groupSlug by removing the last segment of projectSlug
        const parts = entitySlug.split('/');
        if (parts.length < 2) {
          // Not enough parts to form a group
          setIssues([]);
          setLoading(false);
          return;
        }
        const groupSlug = normalizeSlug(parts.slice(0, parts.length - 1).join('/'));

        // Fetch issues from this single group
        const groupIssues = await gitLabApi.getIssuesByGroup(groupSlug);

        // Filter issues by project_path and user assignment
        const filteredIssues = groupIssues.reduce<GitLabIssue[]>((acc, issue) => {
          if (issue.project_path === projectSlug) {
            // Check user assignment
            const assignedToUser = !user || issue.assignees.some(
              (assignee: GitLabIssue['assignees'][number]) =>
                assignee.username.toLowerCase() === user.toLowerCase()
            );

            if (assignedToUser) {
              acc.push({
                ...issue,
                created_at: dayjs(issue.created_at).format('YYYY-MM-DD HH:mm:ss'),
                due_date: issue.milestone?.due_date
                  ? dayjs(issue.milestone.due_date).format('YYYY-MM-DD')
                  : issue.due_date
                  ? dayjs(issue.due_date).format('YYYY-MM-DD')
                  : '-',
              });
            }
          }
          return acc;
        }, []);

        setIssues(filteredIssues);
      } catch (error: unknown) {
        if (error instanceof Error) {
          errorApi.post(new Error(`Failed to fetch issues: ${error.message}`));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchIssues();
  }, [entity, gitLabApi, errorApi, user, projectName]);

  const filteredIssues = issues.filter(issue => {
    if (!showClosed && issue.state === 'closed') {
      return false;
    }
    return true;
  });

  const columns: TableColumn<GitLabIssue>[] = [
    { title: 'Title', field: 'title' },
    {
      title: 'Assignees',
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          {rowData.assignees.map((assignee: GitLabIssue['assignees'][number]) => (
            <Box key={assignee.id} display="flex" alignItems="center" marginRight={1}>
              <Avatar
                src={assignee.avatar_url}
                alt={assignee.name}
                style={{ marginRight: 4, width: 16, height: 16 }}
              />
              <Link href={assignee.web_url} target="_blank" rel="noopener noreferrer">
                <Typography>{assignee.name}</Typography>
              </Link>
            </Box>
          ))}
        </Box>
      ),
    },
    { title: 'Created At', field: 'created_at' },
    { title: 'Due Date', field: 'due_date' },
    {
      title: 'State',
      render: (rowData) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingX={1.5}
          paddingY={0.5}
          borderRadius={12}
          bgcolor={rowData.state === 'opened' ? '#ff7f7f' : '#90ee90'}
          minWidth={80}
        >
          <Typography style={{ fontWeight: 'bold', color: 'black' }}>
            {rowData.state}
          </Typography>
        </Box>
      ),
    },
    {
      title: 'Labels',
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          {rowData.labels.map((label) => (
            <Box
              key={label}
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginRight={1}
              paddingX={2}
              paddingY={1}
              borderRadius={6}
              bgcolor={label.toLowerCase().includes('progress') ? '#ffeb3b' : '#add8e6'}
              minWidth={100}
            >
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
      ),
    },
  ];

  const handleRowClick = (rowData?: GitLabIssue) => {
    if (rowData) {
      setSelectedIssue(rowData);
    }
  };

  const handleCloseDialog = () => {
    setSelectedIssue(null);
  };

  const forproject = projectName ? projectName : 'this project';

  return (
    <Box>
      {loading ? (
        <Progress />
      ) : (
        <Table
          title={
            <Box display="flex" alignItems="center">
              <Typography variant="h6" style={{ marginRight: 16 }}>
                {`GitLab Issues: ${forproject}`}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showClosed}
                    onChange={(event) => setShowClosed(event.target.checked)}
                    name="showClosed"
                    color="primary"
                  />
                }
                label="Show closed"
                style={{ marginLeft: 16 }}
              />
            </Box>
          }
          subtitle={`Assigned to: ${user || 'Anyone'}`}
          options={{
            search: true,
            paging: true,
            sorting: true,
          }}
          columns={columns}
          data={filteredIssues}
          onRowClick={(_event, rowData) => handleRowClick(rowData)}
        />
      )}
      {selectedIssue && (
        <Dialog open={true} onClose={handleCloseDialog}>
          <DialogTitle>{selectedIssue.title}</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1">
              <strong>State: </strong>
              <Box
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                px={1.5}
                py={0.5}
                borderRadius={12}
                bgcolor={selectedIssue.state === 'opened' ? '#ff7f7f' : '#90ee90'}
              >
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>
                  {selectedIssue.state}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">
              <strong>Created At: </strong> {selectedIssue.created_at}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Due Date: </strong> {selectedIssue.due_date}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Assignees: </strong>
            </Typography>
            <Box display="flex" alignItems="center">
              {selectedIssue.assignees.map((assignee: GitLabIssue['assignees'][number]) => (
                <Box key={assignee.id} display="flex" alignItems="center" marginBottom={1}>
                  <Avatar
                    src={assignee.avatar_url}
                    alt={assignee.name}
                    style={{ marginRight: 4, width: 20, height: 20 }}
                  />
                  <Link href={assignee.web_url} target="_blank" rel="noopener noreferrer">
                    <Typography>{assignee.name}</Typography>
                  </Link>
                </Box>
              ))}
            </Box>
            <Typography variant="subtitle1">
              <strong>Labels: </strong>
              <Box display="inline-flex" alignItems="center" marginBottom={1}>
                {selectedIssue.labels.map((label) => (
                  <Box
                    key={label}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    px={1.5}
                    py={0.5}
                    borderRadius={12}
                    bgcolor={
                      label.toLowerCase().includes('progress')
                        ? '#fff59c'
                        : '#add8e6'
                    }
                    width="max-content"
                    marginRight={1}
                  >
                    <Typography style={{ fontWeight: 'bold', color: 'black' }}>
                      {label}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Typography>
            <Typography variant="subtitle1">
              <strong>Description:</strong> {selectedIssue.description}
            </Typography>
            <Box marginTop={2} display="flex" justifyContent="space-between">
              <Button onClick={handleCloseDialog} color="primary" variant="outlined">
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => window.open(selectedIssue.web_url, '_blank')}
              >
                View in GitLab
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};
