import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { costInsightsApiRef, ExampleCostInsightsClient } from '@backstage-community/plugin-cost-insights';
import { ecsServicesApiRef, EcsServicesClient} from '@internal/backstage-plugin-costs';
import { tfStateApiRef, TfStateApiClient} from '@itsyndicate/backstage-plugin-s3-tfstate-parser';
import { awsCloudWatchApiRef, AwsCloudWatchClient } from '@internal/backstage-plugin-monitoring';
import { userLinksApiRef, UserLinksClient } from '@itsyndicate/backstage-plugin-user-links';
import { awsHealthApiRef, AwsHealthClient } from '@itsyndicate/backstage-plugin-aws-health-dashboard';
import { awsCloudTrailApiRef, AwsCloudTrailClient } from '@internal/backstage-plugin-security';
import { awsIamApiRef, AwsIamClient } from '@internal/backstage-plugin-security';
import { awsSecurityGroupsApiRef, AwsSecurityGroupsClient } from '@internal/backstage-plugin-security';
import { GitLabApiClient, gitLabApiRef } from '@internal/backstage-plugin-taskpage';
import { automaticDashboardApiRef, AutomaticDashboardClient } from '@itsyndicate/backstage-plugin-aws-metricdashboards';
import { awsResourcesApiRef, AwsResourcesClient } from '@itsyndicate/backstage-plugin-aws-resources';
import { hetznerResourcesApiRef, HetznerResourcesClient } from '@internal/backstage-plugin-hetzner-services';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: automaticDashboardApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new AutomaticDashboardClient(discoveryApi, identityApi),
  }),
  createApiFactory({
      api: awsResourcesApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) => new AwsResourcesClient(discoveryApi, identityApi),
    }),
  createApiFactory({
      api: hetznerResourcesApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) => new HetznerResourcesClient(discoveryApi, identityApi),
    }),
  createApiFactory({
    api: userLinksApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new UserLinksClient({ discoveryApi, identityApi }),
  }),
  createApiFactory({
    api: tfStateApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new TfStateApiClient(discoveryApi, identityApi),
  }),
  createApiFactory({
    api: ecsServicesApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new EcsServicesClient(discoveryApi, identityApi),
  }),
  createApiFactory({
    api: awsCloudWatchApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new AwsCloudWatchClient(discoveryApi, identityApi),
  }),
  createApiFactory({
    api: gitLabApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new GitLabApiClient(discoveryApi, identityApi), // Pass the arguments directly
  }),
  createApiFactory({
    api: awsSecurityGroupsApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new AwsSecurityGroupsClient(discoveryApi, identityApi),
  }),
  createApiFactory({
    api: awsIamApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new AwsIamClient(discoveryApi, identityApi),
  }),
  createApiFactory({
    api: awsCloudTrailApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new AwsCloudTrailClient({ discoveryApi, identityApi })
  }),
  createApiFactory(costInsightsApiRef, new ExampleCostInsightsClient()),
  ScmAuth.createDefaultApiFactory(),
];
