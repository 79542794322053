// plugins/monitoring/src/components/StandaloneLogsComponent.tsx

import React, { useEffect, useState, ChangeEvent } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { awsCloudWatchApiRef, LogsResponse, CategoryLogs } from '../AwsCloudWatchApi';
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  TextField,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { Progress } from '@backstage/core-components';

// Define custom styles using Material-UI's makeStyles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    searchBar: {
      marginBottom: theme.spacing(3),
    },
    card: {
      marginBottom: theme.spacing(3),
    },
    tableContainer: {
      maxHeight: 400, // Adjust as needed
      minHeight: 400, // Adjust as needed
    },
    noData: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  }),
);

/**
 * StandaloneLogsComponent
 * Displays categorized CloudWatch log groups with search functionality.
 */
export const StandaloneLogsComponent = () => {
  const classes = useStyles();
  const awsCloudWatchApi = useApi(awsCloudWatchApiRef);
  const [logsData, setLogsData] = useState<CategoryLogs[]>([]);
  const [filteredLogsData, setFilteredLogsData] = useState<CategoryLogs[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      try {
        const logs: LogsResponse[] = await awsCloudWatchApi.getLogs();
        console.log('Fetched logs data:', logs);
        const groupedLogs: CategoryLogs[] = groupLogsByCategory(logs);
        setLogsData(groupedLogs);
        setFilteredLogsData(groupedLogs);
      } catch (err) {
        console.error('Error fetching logs:', err);
        setError('Failed to load logs.');
      }
      setLoading(false);
    };

    fetchLogs();
  }, [awsCloudWatchApi]);

  /**
   * Groups LogsResponse[] by a specified category.
   * Modify the grouping logic based on your desired categorization.
   * For example, you can group by application, environment, etc.
   *
   * @param logs Array of LogsResponse
   * @returns Array of CategoryLogs
   */
  const groupLogsByCategory = (logs: LogsResponse[]): CategoryLogs[] => {
    const groups: { [category: string]: LogsResponse[] } = {};
  
    logs.forEach((log: LogsResponse) => {
      const logGroupName = log.logGroupName;
  
      // Check if logGroupName contains '/'
      if (!logGroupName.includes('/')) {
        // If no '/', set category to 'Other'
        const category = 'Other';
        const displayName = logGroupName;
  
        const updatedLog = { ...log, logGroupName: displayName };
  
        if (groups[category]) {
          groups[category].push(updatedLog);
        } else {
          groups[category] = [updatedLog];
        }
      } else {
        // Existing logic
        const parts = logGroupName.split('/').filter(part => part.length > 0);
  
        // Extract category from logGroupName
        let category = '';
        let displayName = '';
        if (parts.length >= 3 && parts[0].toLowerCase() === 'aws') {
          category = parts[1];
          displayName = parts.slice(2).join('/');
        } else if (parts.length >= 2) {
          category = parts[0];
          displayName = parts.slice(1).join('/');
        } else if (parts.length === 1) {
          category = parts[0];
          displayName = parts[0];
        } else {
          category = 'Other';
          displayName = logGroupName;
        }
  
        const updatedLog = { ...log, logGroupName: displayName };
  
        if (groups[category]) {
          groups[category].push(updatedLog);
        } else {
          groups[category] = [updatedLog];
        }
      }
    });
  
    const categoryLogs: CategoryLogs[] = Object.keys(groups).map(category => ({
      category,
      logGroups: groups[category],
    }));
  
    return categoryLogs;
  };
  

  /**
   * Handler for search input changes
   */
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.trim() === '') {
      setFilteredLogsData(logsData);
      return;
    }

    const lowercasedValue = value.toLowerCase();

    const filtered = logsData
      .map((category) => {
        const filteredLogGroups = category.logGroups.filter(
          (logGroup) =>
            logGroup.logGroupName.toLowerCase().includes(lowercasedValue) ||
            logGroup.region.toLowerCase().includes(lowercasedValue)
        );
        return {
          ...category,
          logGroups: filteredLogGroups,
        };
      })
      .filter((category) => category.logGroups.length > 0);

    setFilteredLogsData(filtered);
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" className={classes.container}>
        <Progress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container justifyContent="center" className={classes.container}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Grid>
    );
  }
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  return (
    <div className={classes.container}>
      <Typography variant="h4" gutterBottom>
        Log Groups
      </Typography>
  
      {/* Search Bar */}
      <TextField
        label="Search Log Groups"
        variant="outlined"
        fullWidth
        className={classes.searchBar}
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search by log group name or region"
      />
  
      {filteredLogsData.length === 0 ? (
        <Paper className={`${classes.card} ${classes.noData}`}>
          <Typography variant="h6">No log groups found.</Typography>
        </Paper>
      ) : (
        <Grid container spacing={3}>
          {filteredLogsData.map(categoryData => (
            <Grid item xs={12} sm={6} md={3} key={categoryData.category}>
              <Paper className={classes.card} elevation={3}>
              <Typography variant="h6" gutterBottom align="center">
                {capitalizeFirstLetter(categoryData.category)}
              </Typography>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table size="small" aria-label={`${categoryData.category} log groups`}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Log Group Name</TableCell>
                        <TableCell>Region</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categoryData.logGroups.map(logGroup => (
                        <TableRow key={logGroup.logGroupName} hover>
                          <TableCell>
                            <Link href={logGroup.consoleLink} target="_blank" rel="noopener noreferrer">
                              {logGroup.logGroupName}
                            </Link>
                          </TableCell>
                          <TableCell>{logGroup.region}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
  
}
export default StandaloneLogsComponent;
