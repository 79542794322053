import React from 'react';
import LogoFull from '../Root/LogoSide'; // Import your custom logo
import { ToolboxHomepageCard } from '@drodil/backstage-plugin-toolbox';
import { Page, Content, InfoCard, Header } from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  CustomHomepageGrid,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useLogoStyles } from './shared';
import { ECSwidget, Lambdawidget, EFSwidget, RDSwidget } from '@internal/backstage-plugin-monitoring';
import { HeaderWorldClock } from '@backstage/plugin-home';
import { CostDashboardWidget } from '@internal/backstage-plugin-costs';
import { createComponentExtension } from '@backstage/core-plugin-api';
import { MarkdownPage } from '../customs/Docs';

const clockConfigs = [
  { label: 'New York', timeZone: 'America/New_York' },
  { label: 'Greenwich', timeZone: 'UTC' },
  { label: 'Kyiv', timeZone: 'Europe/Kiev' },
  { label: 'Tokyo', timeZone: 'Asia/Tokyo' },
];
const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};
const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '40vw',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  descriptionText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  widgetContainer: {
    width: '300px', // Set the desired width
    height: '300px', // Set the desired height
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  searchBarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));
export const HomePage = () => {
  const { container } = useLogoStyles();
  const classes = useStyles();
  return (
    <Page  themeId="website">
        <Header pageTitleOverride="Home" title={<HomePageCompanyLogo className={container} logo={<LogoFull />} />}>
          <div className={classes.headerContainer}>
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
          </div>
          <HeaderWorldClock
              clockConfigs={clockConfigs}
              customTimeFormat={timeFormat}
            />
        </Header>

      <Content>
        <Grid container item xs={12} justifyContent="center">
          <Typography variant="body1" className={classes.descriptionText}>
            Welcome to the ITSyndicate internal platform.<br />
            This page is editable to suit your needs, just click the edit button and shape the view below as you wish.
          </Typography>
        </Grid>
        <CustomHomepageGrid>
          <ECSwidget />
          <EFSwidget />
          <RDSwidget />
          <Lambdawidget />
          <ToolboxHomepageCard />
          <Grid item className={classes.widgetContainer}>
            <CostDashboardWidget />
          </Grid>
        </CustomHomepageGrid>
      </Content>
    </Page>
  );
};
