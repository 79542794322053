import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  IconButton,
  Avatar,
  Typography,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { hetznerResourcesApiRef } from '../api/HetznerResourcesApi';
import { useAsync } from 'react-use';
import {
  Server as ServerIcon,
  HardDrive as VolumeIcon,
  Camera as SnapshotIcon,
  Globe as FloatingIpIcon,
  Shuffle as LoadBalancerIcon,
  Wifi as NetworkIcon,
  Shield as FirewallIcon,
  Grid as PlacementGroupIcon,
  Cloud as DefaultIcon,
  Users as GroupIcon, // Imported but not currently used
} from 'react-feather';
import { Progress, InfoCard } from '@backstage/core-components';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: theme.spacing(2),
  },
  card: {
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.background.default,
  },
  count: {
    fontSize: '18px',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(0.5),
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',       // Display contents in a flexible row
    flexWrap: 'wrap',      // Wrap cards to the next line if needed
    justifyContent: 'flex-start', 
    gap: theme.spacing(2), // Optional spacing between cards
  },
  infoCardContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const hetznerIcons: Record<string, JSX.Element> = {
  servers: <ServerIcon />,
  volumes: <VolumeIcon />,
  snapshots: <SnapshotIcon />,
  floating_ips: <FloatingIpIcon />,
  load_balancers: <LoadBalancerIcon />,
  networks: <NetworkIcon />,
  firewalls: <FirewallIcon />,
  placement_groups: <PlacementGroupIcon />,
};

interface HetznerResource {
  id?: number;
  name?: string;
  status?: string;
  [key: string]: any;
}

function formatResourceDetails(resourceType: string, resource: HetznerResource): string[] {
  const details: string[] = [];

  switch (resourceType) {
    case 'servers':
      details.push(`ID: ${resource.id}`);
      if (resource.name) details.push(`Name: ${resource.name}`);
      if (resource.status) details.push(`Status: ${resource.status}`);
      if (resource.server_type?.name) details.push(`Server Type: ${resource.server_type.name}`);
      if (resource.datacenter?.name) details.push(`Datacenter: ${resource.datacenter.name}`);
      if (resource.public_net?.ipv4?.ip) details.push(`Public IP: ${resource.public_net.ipv4.ip}`);
      break;

    case 'volumes':
      details.push(`ID: ${resource.id}`);
      if (resource.name) details.push(`Name: ${resource.name}`);
      if (resource.size) details.push(`Size (GB): ${resource.size}`);
      if (resource.status) details.push(`Status: ${resource.status}`);
      if (resource.location?.name) details.push(`Location: ${resource.location.name}`);
      break;

    case 'snapshots':
      details.push(`ID: ${resource.id}`);
      if (resource.name) details.push(`Name: ${resource.name}`);
      if (resource.description) details.push(`Description: ${resource.description}`);
      if (resource.type) details.push(`Type: ${resource.type}`);
      if (resource.status) details.push(`Status: ${resource.status}`);
      if (resource.os_flavor) details.push(`OS: ${resource.os_flavor} ${resource.os_version || ''}`);
      break;

    case 'floating_ips':
      details.push(`ID: ${resource.id}`);
      if (resource.name) details.push(`Name: ${resource.name}`);
      if (resource.ip) details.push(`IP: ${resource.ip}`);
      if (resource.type) details.push(`Type: ${resource.type}`);
      if (resource.description) details.push(`Description: ${resource.description}`);
      break;

    case 'load_balancers':
      details.push(`ID: ${resource.id}`);
      if (resource.name) details.push(`Name: ${resource.name}`);
      if (resource.load_balancer_type?.description) {
        details.push(`Type: ${resource.load_balancer_type.description}`);
      }
      if (resource.algorithm?.type) details.push(`Algorithm: ${resource.algorithm.type}`);
      if (resource.location?.name) details.push(`Location: ${resource.location.name}`);
      details.push(`Services: ${resource.services?.length || 0}`);
      details.push(`Targets: ${resource.targets?.length || 0}`);
      break;

    case 'networks':
      details.push(`ID: ${resource.id}`);
      if (resource.name) details.push(`Name: ${resource.name}`);
      if (resource.ip_range) details.push(`IP Range: ${resource.ip_range}`);
      details.push(`Servers in Network: ${resource.servers?.length || 0}`);
      details.push(`Load Balancers in Network: ${resource.load_balancers?.length || 0}`);
      details.push(`Routes: ${resource.routes?.length || 0}`);
      break;

    case 'firewalls':
      details.push(`ID: ${resource.id}`);
      if (resource.name) details.push(`Name: ${resource.name}`);
      details.push(`Rules: ${resource.rules?.length || 0}`);
      if (resource.labels) {
        const labelsStr = Object.keys(resource.labels)
          .map(k => `${k}=${resource.labels[k]}`)
          .join(', ');
        details.push(`Labels: ${labelsStr}`);
      }
      break;

    case 'placement_groups':
      details.push(`ID: ${resource.id}`);
      if (resource.name) details.push(`Name: ${resource.name}`);
      if (resource.type) details.push(`Type: ${resource.type}`);
      details.push(`Servers: ${resource.servers?.length || 0}`);
      break;

    default:
      if (resource.id) details.push(`ID: ${resource.id}`);
      if (resource.name) details.push(`Name: ${resource.name}`);
      if (resource.status) details.push(`Status: ${resource.status}`);
      break;
  }

  return details;
}

export const HetznerResourcesWidget = () => {
  const classes = useStyles();
  const hetznerResourcesApi = useApi(hetznerResourcesApiRef);
  const [openDialog, setOpenDialog] = useState<string | null>(null);

  const { value: resourceData, loading, error } = useAsync(async () => {
    return await hetznerResourcesApi.getHetznerResources();
  }, [hetznerResourcesApi]);

  const handleDialogOpen = (type: string) => {
    setOpenDialog(type);
  };

  const handleDialogClose = () => {
    setOpenDialog(null);
  };

  if (loading) return <Progress />;
  if (error) return <p>Error fetching Hetzner resources: {error.message}</p>;
  if (!resourceData) return <p>No data available</p>;

  return (
    <>
      <Typography variant="h3">Hetzner Cloud Usage</Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2} justifyContent="flex-start">
            {Object.keys(resourceData).map((resourceType) => {
              const resourcesArray = resourceData[resourceType] || [];
              const count = Array.isArray(resourcesArray) ? resourcesArray.length : 0;
              return (
                <Grid item xs={12} sm={6} md={4} key={resourceType}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <IconButton
                      onClick={() => handleDialogOpen(resourceType)}
                      className={classes.card}
                      aria-label={`View details for ${resourceType}`}
                    >
                      {hetznerIcons[resourceType] || <DefaultIcon />}
                    </IconButton>
                    <div>
                      <div className={classes.count}>{count}</div>
                      <div className={classes.label}>{resourceType}</div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>

        {openDialog && (
          <Dialog open={!!openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
            <DialogTitle>Details for {openDialog}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {(resourceData[openDialog] || []).map((resource: HetznerResource, idx: number) => {
                const details = formatResourceDetails(openDialog, resource);
                const title = resource.name || `ID: ${resource.id}` || `Resource #${idx + 1}`;
                
                // Check if this is a server and it's running
                const isServerRunning = openDialog === 'servers' && resource.status === 'running';

                return (
                  <div className={classes.infoCardContainer} key={resource.id || idx}>
                    <InfoCard
                      title={title}
                      headerProps={{
                        avatar: (
                          <Avatar
                            className={classes.avatar}
                            style={isServerRunning ? { backgroundColor: 'lightgreen' } : {}}
                          >
                            {hetznerIcons[openDialog] || <DefaultIcon style={{ stroke: 'black' }} />}
                          </Avatar>
                        ),
                      }}
                    >
                      {details.slice(1).map((d, i) => (
                        <Typography variant="body2" key={i}>
                          {d}
                        </Typography>
                      ))}
                    </InfoCard>
                  </div>
                );
              })}
            </DialogContent>
          </Dialog>
        )}
      </Card>
    </>
  );
};
