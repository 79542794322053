import { createApiRef, DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export const hetznerResourcesApiRef = createApiRef<HetznerResourcesApi>({
  id: 'plugin.hetzner-resources.service',
});

export interface HetznerResourcesApi {
  getHetznerResources(): Promise<any>;
}

export class HetznerResourcesClient implements HetznerResourcesApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly identityApi: IdentityApi,
  ) {}

  async getHetznerResources(): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('hetzner-services');
    const url = `${baseUrl}/resources`;

    // Get the user's token if needed (depending on your auth configuration)
    const { token } = await this.identityApi.getCredentials();

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch Hetzner resources, status ${response.status}`);
    }

    return await response.json();
  }
}
