import React from 'react';
import { Content, PageWithHeader, SupportButton } from '@backstage/core-components';
import { Box, Grid } from '@mui/material'; // Updated import from @mui/material
import { GitLabIssuesCard } from '@internal/backstage-plugin-taskpage';


export const TaskPage = () => {
  return (
    <PageWithHeader themeId="component" title="Task Management">
      {/* Increase right padding to push the button further to the right */}

      <Content>
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '40px', paddingTop: '10px' }}>
          <SupportButton>Tasks on the project.</SupportButton>
        </div>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={12} xs={12}>
            <GitLabIssuesCard />
          </Grid>
        </Grid>
      </Content>
    </PageWithHeader>
  );
};

