import React, { useState, useEffect } from 'react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { gitLabApiRef } from './GitlabIssuesApi';
import { Progress, Table, TableColumn } from '@backstage/core-components';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  Button,
  Select,
  MenuItem,
  Link,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { RELATION_OWNED_BY, EntityRelation } from '@backstage/catalog-model';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import dayjs from 'dayjs';

type GitLabIssue = {
  id: number;
  title: string;
  description: string;
  projectId: string;
  projectName: string;
  groupName: string;
  state: string;
  created_at: string;
  due_date: string | null;
  assignees: {
    id: number;
    username: string;
    name: string;
    avatar_url: string;
    web_url: string;
  }[];
  web_url: string;
  labels: string[];
  mainGroupLink?: string; // Added mainGroupLink property
};

type GitLabProjectGroup = {
  groupSlug: string;
  groupName: string;
  projectIds: { id: string; name: string }[];
};

export const GitLabIssuesCard = () => {
  const gitLabApi = useApi(gitLabApiRef);
  const errorApi = useApi(errorApiRef);
  const catalogApi = useApi<CatalogApi>(catalogApiRef);
  const [issues, setIssues] = useState<GitLabIssue[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState<GitLabIssue | null>(null);
  const [projectGroups, setProjectGroups] = useState<GitLabProjectGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>('all');
  const [showClosed, setShowClosed] = useState(false);
  const [mainGroupLink, setMainGroupLink] = useState<string>(''); // New state for mainGroupLink

  useEffect(() => {
    const fetchIssues = async () => {
      setLoading(true);
      try {
        const response = await catalogApi.getEntities({
          filter: [{ kind: 'component' }],
          fields: ['metadata', 'relations'],
        });

        const projectsGroups: GitLabProjectGroup[] = [];

        response.items.forEach((entity: any) => {
          const entitySlug = entity.metadata.annotations?.['gitlab.com/project-slug'];
          if (!entitySlug) {
            // No projectSlug means no issues to fetch
            setIssues([]);
            setLoading(false);
            return;
          }

          const normalizeSlug = (slug: string) => slug.replace(/\//g, '-').toLowerCase();

          // Derive groupSlug by removing the last segment of projectSlug
          const parts = entitySlug.split('/');
          if (parts.length < 2) {
            // Not enough parts to form a group
            setIssues([]);
            setLoading(false);
            return;
          }
          const groupSlug = normalizeSlug(parts.slice(0, parts.length - 1).join('/'));
          const ownedByRelations =
            entity?.relations?.filter(
              (r: EntityRelation) => r.type === RELATION_OWNED_BY
            ) || [];
          ownedByRelations.forEach((relation: EntityRelation) => {
            const groupName = relation.targetRef.split('/')[1];
            if (groupName.toLowerCase() === 'guests') return;

            const existingGroup = projectsGroups.find(
              (group) => group.groupName === groupName
            );
            const projectId = entity.metadata.name;
            const projectName = entity.metadata.title || projectId;

            if (existingGroup) {
              existingGroup.projectIds.push({ id: projectId, name: projectName });
            } else {
              projectsGroups.push({
                groupSlug,
                groupName,
                projectIds: [{ id: projectId, name: projectName }],
              });
            }
          });
        });

        // Sort project groups by groupName for ordered dropdown display
        projectsGroups.sort((a, b) => a.groupName.localeCompare(b.groupName));
        setProjectGroups(projectsGroups);

        // Fetch issues for each group and map to include the project name and group name
        const issuesPromises = projectsGroups.map((group) =>
          gitLabApi
            .getIssuesByGroup(group.groupSlug)
            .then((issues) => ({ groupName: group.groupName, issues }))
        );
        const issuesResults = await Promise.all(issuesPromises);
        const allIssues: GitLabIssue[] = [];



        issuesResults.forEach(({ groupName, issues }) => {
          const projectGroup = projectsGroups.find(
            (group) => group.groupName === groupName
          );
          if (projectGroup) {
            issues.forEach((issue) => {
              allIssues.push({
                ...issue,
                projectId: issue.project_id,
                projectName: issue.project_name,
                groupName,
                created_at: dayjs(issue.created_at).format('YYYY-MM-DD HH:mm:ss'),
                due_date: issue.milestone?.due_date
                  ? dayjs(issue.milestone.due_date).format('YYYY-MM-DD')
                  : issue.due_date
                  ? dayjs(issue.due_date).format('YYYY-MM-DD')
                  : '-',
                mainGroupLink: issue.mainGroupLink,
              });
            });
          }
        });

        setIssues(allIssues);
        if (
          allIssues.length > 0 &&
          allIssues[0].mainGroupLink
        ) {
          setMainGroupLink(allIssues[0].mainGroupLink);
        } else {
          setMainGroupLink(''); // Clear if not available
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          errorApi.post(
            new Error(`Failed to fetch issues for groups: ${error.message}`)
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchIssues();
  }, [catalogApi, gitLabApi, errorApi]);

  const handleGroupChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedGroup(event.target.value as string);
  };

  const handleRowClick = (event?: React.MouseEvent, rowData?: GitLabIssue) => {
    if (rowData) {
      setSelectedIssue(rowData);
    }
  };

  // Modify filteredIssues to filter out closed issues if "Show closed" is not selected
  const filteredIssues = issues.filter((issue) => {
    const groupMatch =
      selectedGroup === 'all' || issue.groupName === selectedGroup;
    const stateMatch = showClosed || issue.state !== 'closed';
    return groupMatch && stateMatch;
  });

  const columns: TableColumn<GitLabIssue>[] = [
    { title: 'Title', field: 'title' },
    {
      title: 'Assignees',
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          {rowData.assignees.map((assignee) => (
            <Box
              key={assignee.id}
              display="flex"
              alignItems="center"
              marginRight={1}
            >
              <Avatar
                src={assignee.avatar_url}
                alt={assignee.name}
                style={{ marginRight: 4, width: 16, height: 16 }}
              />
              <Link
                href={assignee.web_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography>{assignee.name}</Typography>
              </Link>
            </Box>
          ))}
        </Box>
      ),
    },
    { title: 'Created At', field: 'created_at' },
    { title: 'Due Date', field: 'due_date' },
    { title: 'Repository', field: 'projectName' },
    {
      title: 'State',
      field: 'state',
      render: (rowData) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingX={1.5}
          paddingY={0.5}
          borderRadius={12}
          bgcolor={rowData.state === 'opened' ? '#ff7f7f' : '#90ee90'}
          width="max-content"
        >
          <Typography
            style={{
              fontWeight: 'bold',
              color: rowData.state === 'opened' ? '#254225' : '#3d3d3d',
            }}
          >
            {rowData.state}
          </Typography>
        </Box>
      ),
      sorting: true,
    },
    {
      title: 'Labels',
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          {rowData.labels.map((label) => (
            <Box
              key={label}
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingX={1.5}
              paddingY={0.5}
              borderRadius={12}
              bgcolor={
                label.toLowerCase().includes('progress')
                  ? '#fff59c'
                  : '#add8e6'
              }
              width="max-content"
            >
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
      ),
    },
  ];

  return (
          <Box>
          {loading ? (
            <Progress />
          ) : (
            <Table
              title={
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  {/* Left-aligned elements */}
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" style={{ marginRight: 16 }}>
                      GitLab Issues
                    </Typography>
                    <Select
                      value={selectedGroup}
                      onChange={handleGroupChange}
                      displayEmpty
                      variant="outlined"
                      style={{ minWidth: 150 }}
                    >
                      <MenuItem value="all">All Groups</MenuItem>
                      {projectGroups.map((group) => (
                        <MenuItem key={group.groupName} value={group.groupName}>
                          {group.groupName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showClosed}
                          onChange={(event) => setShowClosed(event.target.checked)}
                          name="showClosed"
                          color="primary"
                        />
                      }
                      label="Show closed"
                      style={{ marginLeft: 16 }}
                    />
                  </Box>
                  {/* Right-aligned GitLab board button */}
                  <Box>
                    {mainGroupLink &&
                      !mainGroupLink.endsWith('groups') &&
                      selectedGroup &&
                      selectedGroup !== 'all' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            window.open(
                              `${mainGroupLink}/${selectedGroup}/-/boards`,
                              '_blank'
                            )
                          }
                          style={{ marginRight: 16 }}
                        >
                          GitLab board
                        </Button>
                      )}
                    {mainGroupLink &&
                      !mainGroupLink.endsWith('groups') &&
                      selectedGroup &&
                      selectedGroup === 'all' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            window.open(`${mainGroupLink}/-/boards`, '_blank')
                          }
                          style={{ marginRight: 16 }}
                        >
                          GitLab board
                        </Button>
                      )}
                  </Box>
                </Box>
              }
              options={{ sorting: true }}
              columns={columns}
              data={filteredIssues}
              onRowClick={handleRowClick}
            />
          )}
          {selectedIssue && (
            <Dialog open={true} onClose={() => setSelectedIssue(null)}>
              <DialogTitle>{selectedIssue.title}</DialogTitle>
              <DialogContent>
                <Typography variant="subtitle1">
                  <strong>Project: </strong> {selectedIssue.projectName}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>State: </strong> 
                  <Box display="inline-flex" alignItems="center" justifyContent="center" px={1.5} py={0.5} borderRadius={12} bgcolor={selectedIssue.state === 'opened' ? '#ff7f7f' : '#90ee90'}>
                    <Typography style={{ fontWeight: 'bold', color: 'black' }}>{selectedIssue.state}</Typography>
                  </Box>
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Created At: </strong> {selectedIssue.created_at}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Due Date: </strong> {selectedIssue.due_date}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Assignees: </strong>
                </Typography>
                <Box display="flex" alignItems="center">
                  {selectedIssue.assignees.map((assignee) => (
                    <Box key={assignee.id} display="flex" alignItems="center" marginBottom={1}>
                      <Avatar src={assignee.avatar_url} alt={assignee.name} style={{ marginRight: 4, width: 20, height: 20 }} />
                      <Link href={assignee.web_url} target="_blank" rel="noopener noreferrer">
                        <Typography>{assignee.name}</Typography>
                      </Link>
                    </Box>
                  ))}
                </Box>
                <Typography variant="subtitle1">
                  <strong>Labels: </strong> 
                  <Box display="inline-flex" alignItems="center" marginBottom={1}>
                    {selectedIssue.labels.map((label) => (
                      <Box
                        key={label}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        px={1.5}
                        py={0.5}
                        borderRadius={12}
                        bgcolor={
                          label.toLowerCase().includes('progress')
                            ? '#fff59c'
                            : '#add8e6'
                        }
                        width="max-content"
                        marginRight={1}
                      >
                        <Typography style={{ fontWeight: 'bold', color: 'black' }}>{label}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Description:</strong> {selectedIssue.description}
                </Typography>
                <Box marginTop={2} display="flex" justifyContent="space-between">
                  <Button onClick={() => setSelectedIssue(null)} color="primary" variant="outlined">
                    Close
                  </Button>
                  <Button color="primary" variant="contained" onClick={() => window.open(selectedIssue.web_url, '_blank')}>
                    View in GitLab
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          )}
        </Box>
  );
};
