import React, { useEffect, useState } from 'react';
import { Route, Routes, Link, useLocation, Navigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Link as Links,
} from '@material-ui/core';
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';
import StorageIcon from '@material-ui/icons/Storage'; // Import Storage Icon
import { useApi } from '@backstage/core-plugin-api';
import { awsCloudWatchApiRef, Alarm, DatabaseStatuses, LambdaErrorCount, LatestBackupData, SSLCheckStatus, WebsiteHealthStatus } from '../../AwsCloudWatchApi';
import { Content, PageWithHeader, Progress, SupportButton } from '@backstage/core-components';
import { MetricsComponent } from '../MetricsComponent';
import { AlarmsComponent } from '../AlarmsComponent';
import StandaloneLogsComponent from '../StandaloneLogsComponent';

// Define custom styles using Material-UI's styled API
const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const TabContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const OverviewCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));
interface EfsDetail {
  Id: string;
  StorageClass?: string;
  SizeGB: number;
}
/**
 * MonitoringPage
 * Main page component that includes tab navigation and routes to different monitoring sections.
 */
export const MonitoringPage = () => {
  const location = useLocation();

  // Determine the active tab based on the current path
  const getActiveTab = () => {
    if (location.pathname.startsWith('/monitoring/metrics')) return '/monitoring/metrics';
    if (location.pathname.startsWith('/monitoring/logging')) return '/monitoring/logging';
    if (location.pathname.startsWith('/monitoring/alerts')) return '/monitoring/alerts';
    return '/monitoring'; // Default to Overview
  };

  const activeTab = getActiveTab();

  return (
    <PageWithHeader themeId="tool" title="Monitoring, Logging and Alerting">
      <Content>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
          <TabNavigation activeTab={activeTab} />
          <SupportButton>Project monitoring page.</SupportButton>
        </div>
        <TabContent>
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="/metrics" element={<MetricsComponent />} />
            <Route path="/logging" element={<StandaloneLogsComponent />} />
            <Route path="/alerts" element={<AlarmsComponent />} />
            <Route path="*" element={<Navigate to="/monitoring" replace />} />
          </Routes>
        </TabContent>
      </Content>
    </PageWithHeader>
  );
};


/**
 * TabNavigation
 * Component for rendering navigation tabs.
 */
const TabNavigation = ({ activeTab }: { activeTab: string }) => (
  <StyledTabs
    value={activeTab}
    aria-label="monitoring navigation tabs"
    indicatorColor="primary"
    textColor="primary"
    variant="scrollable"
    scrollButtons="auto"
  >
    <Tab label="Overview" value="/monitoring" component={Link} to="/monitoring" />
    <Tab label="Metrics" value="/monitoring/metrics" component={Link} to="/monitoring/metrics" />
    <Tab label="Logging" value="/monitoring/logging" component={Link} to="/monitoring/logging" />
    <Tab label="Alerts" value="/monitoring/alerts" component={Link} to="/monitoring/alerts" />
    <Tab label="Docs" value="/docs" component={Link} to="/docs" />
  </StyledTabs>
);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%',
      position: 'relative', // For positioning elements
    },
    chartContainer: {
      width: '100%',
      height: 250,
    },
    metricTitle: {
      marginBottom: theme.spacing(1),
    },
    alertButton: {
      marginTop: theme.spacing(1),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    storageIcon: {
      fontSize: 60,
      color: theme.palette.primary.main,
    },
    storageSize: {
      marginTop: theme.spacing(1),
      fontWeight: 'bold',
    },
    labelUnderGraph: {
      marginTop: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    storageCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      height: '100%',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    storageDetails: {
      padding: theme.spacing(2),
    },
    efsList: {
      maxHeight: '60vh',
      overflowY: 'auto',
    },
    efsItem: {
      marginBottom: theme.spacing(2),
    },
  }),
);
/**
 * Overview
 * Component that displays an overview of key metrics, alarms, and dashboards.
 */
export const Overview = () => {
  const awsCloudWatchApi = useApi(awsCloudWatchApiRef);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const [ecsServiceCount, setEcsServiceCount] = useState<number>(0);
  const [alarmsInAlarm, setAlarmsInAlarm] = useState<number>(0);

  // New state variables
  const [lambdaFunctions, setLambdaFunctions] = useState<LambdaErrorCount[]>([]);
  const [lambdaErrorCountSum, setLambdaErrorCountSum] = useState<number>(0);

  const [websiteHealthStatuses, setWebsiteHealthStatuses] = useState<WebsiteHealthStatus[]>([]);
  const [sslCheckStatuses, setSslCheckStatuses] = useState<SSLCheckStatus[]>([]);

  const [databaseStatuses, setDatabaseStatuses] = useState<DatabaseStatuses | null>(null);

  const [latestBackupData, setLatestBackupData] = useState<LatestBackupData[]>([]);

  // Counts for website and SSL
  const [unhealthyWebsitesCount, setUnhealthyWebsitesCount] = useState<number>(0);
  const [sslExpiringSoonCount, setSslExpiringSoonCount] = useState<number>(0);

  // Counts for DynamoDB
  const [dynamoDbTablesCount, setDynamoDbTablesCount] = useState<number>(0);
  const [dynamoDbTotalItemCount, setDynamoDbTotalItemCount] = useState<number>(0);

  // Dialog state variables
  const [lambdaDialogOpen, setLambdaDialogOpen] = useState<boolean>(false);
  const [websiteDialogOpen, setWebsiteDialogOpen] = useState<boolean>(false);
  const [dynamoDbDialogOpen, setDynamoDbDialogOpen] = useState<boolean>(false);
  
  const [efsDetails, setEfsDetails] = useState<EfsDetail[]>([]);
  const [openStorageDialog, setOpenStorageDialog] = useState<boolean>(false);
  const handleOpenStorageDialog = () => {
    setOpenStorageDialog(true);
  };
  const classes = useStyles();
  const sanitizeName = (name: string): string => {
    return name.replace(/_/g, ' ');
  };

  const handleCloseStorageDialog = () => {
    setOpenStorageDialog(false);
  };

  const totalEfsSize = efsDetails.reduce((acc, efs) => acc + efs.SizeGB, 0);
  const formattedTotalEfsSize = Math.round(totalEfsSize * 100) / 100;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          serviceCount,
          alarms,
          lambdaErrorCounts,
          websiteHealthStatuses,
          sslCheckStatuses,
          databaseStatuses,
          latestBackupData,
          efsData,
        ] = await Promise.all([
          awsCloudWatchApi.getEcsServiceCount(),
          awsCloudWatchApi.getAlarms(),
          awsCloudWatchApi.getLambdaErrorCounts(),
          awsCloudWatchApi.getWebsiteHealthStatuses(),
          awsCloudWatchApi.getSSLCheckStatuses(),
          awsCloudWatchApi.getDatabaseStatuses(),
          awsCloudWatchApi.getLatestBackup(),
          awsCloudWatchApi.getEfsMetrics(),
        ]);

        setEcsServiceCount(serviceCount);

        const alarmsInAlarmCount = alarms.filter((alarm: Alarm) => alarm.StateValue === 'ALARM').length;
        setAlarmsInAlarm(alarmsInAlarmCount);

        setLambdaFunctions(lambdaErrorCounts);

        const totalLambdaErrorCount = lambdaErrorCounts.reduce(
          (sum, lambda) => sum + lambda.errorCount,
          0,
        );
        setLambdaErrorCountSum(totalLambdaErrorCount);

        setWebsiteHealthStatuses(websiteHealthStatuses);
        setSslCheckStatuses(sslCheckStatuses);

        // Calculate counts for website health and SSL
        const unhealthyCount = websiteHealthStatuses.filter(
          status => status.status !== 'healthy',
        ).length;
        setUnhealthyWebsitesCount(unhealthyCount);

        const expiringSoonCount = sslCheckStatuses.filter(
          status => status.daysRemaining !== undefined && status.daysRemaining <= 30,
        ).length;
        setSslExpiringSoonCount(expiringSoonCount);

        setDatabaseStatuses(databaseStatuses);
        setDynamoDbTablesCount(databaseStatuses.dynamoDB.length);
        
        // Calculate total item count for DynamoDB
        const totalDynamoDbItemCount = databaseStatuses.dynamoDB.reduce(
          (sum, table) => sum + (table.itemCount || 0),
          0,
        );
        setDynamoDbTotalItemCount(totalDynamoDbItemCount);
        const processedEfsDetails: EfsDetail[] = [];
        Object.keys(efsData).forEach(efsId => {
          const metricArray = efsData[efsId];
          if (Array.isArray(metricArray)) {
            metricArray.forEach((metric: BaseMetric) => {
              if (metric.Label === 'StorageBytes') {
                const sizeInBytes =
                  metric.Values && metric.Values.length > 0
                    ? metric.Values[metric.Values.length - 1]
                    : 0;
                const sizeInGB = Math.round((sizeInBytes / 1024 ** 3) * 100) / 100;
                processedEfsDetails.push({
                  Id: efsId,
                  SizeGB: sizeInGB,
                });
              }
            });
          }
        });
        setEfsDetails(processedEfsDetails);
        setLatestBackupData(latestBackupData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load overview data.');
      }
      setLoading(false);
    };

    fetchData();
  }, [awsCloudWatchApi]);

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Progress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container justifyContent="center">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Grid>
    );
  }

  return (
    <div>
      <Typography variant="h3" gutterBottom>
        Overview
      </Typography>
      <Grid container spacing={3}>
        {/* ECS Service Count */}
        <Grid item xs={12} sm={6} md={4}>
          <OverviewCard elevation={3}>
            <Typography variant="h6" gutterBottom>
              ECS Service Count
            </Typography>
            <Typography variant="h4">{ecsServiceCount}</Typography>
          </OverviewCard>
        </Grid>

        {/* Alarms in ALARM State */}
        <Grid item xs={12} sm={6} md={4}>
          <Links 
            href="https://console.aws.amazon.com/cloudwatch/home?#alarmsV2:?~(alarmStateFilter~'ALARM)" 
            target="_blank" 
            underline="none" 
            style={{ textDecoration: 'none' }}
          >
            <OverviewCard elevation={3} style={{ cursor: 'pointer' }}>
              <Typography variant="h6" gutterBottom>
                Alarms in ALARM State
              </Typography>
              <Typography variant="h4">{alarmsInAlarm}</Typography>
            </OverviewCard>
          </Links>
        </Grid>

        {/* Lambda Functions */}
        <Grid item xs={12} sm={6} md={4}>
          <OverviewCard
            elevation={3}
            onClick={() => setLambdaDialogOpen(true)}
            style={{ cursor: 'pointer' }}
          >
            <Typography variant="h6" gutterBottom>
              Lambda Functions
            </Typography>
            <Typography variant="body1">Total Functions: {lambdaFunctions.length}</Typography>
            <Typography variant="body1">Total Errors: {lambdaErrorCountSum}</Typography>
          </OverviewCard>
        </Grid>

        {/* Website */}
        <Grid item xs={12} sm={6} md={4}>
          <OverviewCard
            elevation={3}
            onClick={() => setWebsiteDialogOpen(true)}
            style={{ cursor: 'pointer' }}
          >
            <Typography variant="h6" gutterBottom>
              Website
            </Typography>
            <Typography variant="body1">Total Websites: {websiteHealthStatuses.length}</Typography>
            <Typography variant="body1">Unhealthy: {unhealthyWebsitesCount}</Typography>
            <Typography variant="body1">SSL Expiring Soon: {sslExpiringSoonCount}</Typography>
          </OverviewCard>
        </Grid>

        {/* DynamoDB Tables */}
        <Grid item xs={12} sm={6} md={4}>
          <OverviewCard
            elevation={3}
            onClick={() => setDynamoDbDialogOpen(true)}
            style={{ cursor: 'pointer' }}
          >
            <Typography variant="h6" gutterBottom>
              DynamoDB Tables
            </Typography>
            <Typography variant="body1">Tables Count: {dynamoDbTablesCount}</Typography>
            <Typography variant="body1">Total Items: {dynamoDbTotalItemCount}</Typography>
          </OverviewCard>
        </Grid>

        {/* Latest Backup */}
        <Grid item xs={12} sm={6} md={4}>
          <OverviewCard elevation={3}>
            <Typography variant="h6" gutterBottom>
              Latest Backup
            </Typography>
            {latestBackupData.length > 0 ? (
              <>
                <Typography variant="body1">
                  Bucket: {latestBackupData[0].bucketName}
                </Typography>
                <Typography variant="body1">
                  Key: {latestBackupData[0].latestBackup}
                </Typography>
                <Typography variant="body1">
                  Backup date:{' '}
                  {latestBackupData[0].lastModified
                    ? new Date(latestBackupData[0].lastModified).toLocaleString()
                    : '-'}
                </Typography>
              </>
            ) : (
              <Typography variant="body1">No backup data available</Typography>
            )}
          </OverviewCard>
        </Grid>

        {/* RDS Instances */}
        {databaseStatuses?.rds.map(instance => (
          <Grid item xs={12} sm={6} md={4} key={instance.dbInstanceIdentifier}>
            <OverviewCard elevation={3}>
              <Typography variant="h6" gutterBottom>
                RDS Instance: {instance.dbInstanceIdentifier}
              </Typography>
              <Typography variant="body1">Status: {instance.status}</Typography>
              <Typography variant="body1">Engine: {instance.engine}</Typography>
            </OverviewCard>
          </Grid>
        ))}
      </Grid>

      {/* Lambda Functions Dialog */}
      <Dialog
        open={lambdaDialogOpen}
        onClose={() => setLambdaDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Lambda Functions</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Function Name</TableCell>
                <TableCell>Error Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lambdaFunctions.map(lambda => (
                <TableRow key={lambda.functionName}>
                  <TableCell>{lambda.functionName}</TableCell>
                  <TableCell>{lambda.errorCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLambdaDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Website Dialog */}
      <Dialog
        open={websiteDialogOpen}
        onClose={() => setWebsiteDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Website Details</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Health Status</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>URL</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {websiteHealthStatuses.map(status => (
                <TableRow key={status.url}>
                  <TableCell>{status.url}</TableCell>
                  <TableCell>{status.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Typography variant="h6" style={{ marginTop: 20 }}>
            SSL Status
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>URL</TableCell>
                <TableCell>Valid To</TableCell>
                <TableCell>Days Remaining</TableCell>
                <TableCell>Error</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sslCheckStatuses.map(status => (
                <TableRow key={status.url}>
                  <TableCell>{status.url}</TableCell>
                  <TableCell>
                    {status.validTo ? new Date(status.validTo).toLocaleDateString() : '-'}
                  </TableCell>
                  <TableCell>
                    {status.daysRemaining !== undefined ? status.daysRemaining : '-'}
                  </TableCell>
                  <TableCell>{status.error || '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWebsiteDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* DynamoDB Tables Dialog */}
      <Dialog
        open={dynamoDbDialogOpen}
        onClose={() => setDynamoDbDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>DynamoDB Tables</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Table Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Item Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {databaseStatuses?.dynamoDB.map(table => (
                <TableRow key={table.tableName}>
                  <TableCell>{table.tableName}</TableCell>
                  <TableCell>{table.status}</TableCell>
                  <TableCell>{table.itemCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDynamoDbDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
