import React, { useEffect, useState } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { gitLabApiRef } from './GitlabIssuesApi';
import { CircularProgress, Box, Typography, Button, Card, CardContent } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

interface BuildDetails {
  project_path: string;
  id: number;
  project_id: number;
  name?: string;
  status?: string;
  web_url?: string;
  ref?: string;
  sha?: string;
}

export const LatestBuildWidget = () => {
  const { entity } = useEntity();
  const gitLabApi = useApi(gitLabApiRef);
  const errorApi = useApi(errorApiRef);
  
  const [build, setBuild] = useState<BuildDetails | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestBuild = async () => {
      setLoading(true);

      try {
        // Extract projectSlug from entity annotations
        const entitySlug = entity.metadata.annotations?.['gitlab.com/project-slug'];
        if (!entitySlug) {
          // No projectSlug means no builds to show
          setBuild(null);
          setLoading(false);
          return;
        }

        const normalizeSlug = (slug: string) => slug.replace(/\//g, '-').toLowerCase();
        const projectSlug = normalizeSlug(entitySlug);


        // Fetch latest builds by group
        const fetchedBuild = await gitLabApi.getLatestBuildByGroup(projectSlug);
        setBuild(fetchedBuild);
      } catch (error) {
        errorApi.post(new Error(`Failed to fetch the latest build for entity: ${entity.metadata.annotations?.['gitlab.com/project-slug']}`));
      } finally {
        setLoading(false);
      }
    };

    fetchLatestBuild();
  }, [entity, gitLabApi, errorApi]);

  if (loading) {
    return <CircularProgress />;
  }

  // Display message if build data is incomplete or missing
  if (!build || !build.sha) {
    return (
      <Alert severity="info">
        No builds available. Did you configure CI?
      </Alert>
    );
  }

  const statusColor =
    build.status === 'success' ? '#4caf50' : build.status === 'failed' ? '#f44336' : '#9e9e9e';
  const statusText = build.status === 'success' ? 'Passed' : build.status === 'failed' ? 'Failed' : 'Unknown';
  const commitHash = build.sha?.slice(0, 8);

  return (
    <Card style={{ width: '100%' }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
            Last Master Build
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              width: '100%',
              padding: '10px',
              margin: '12px 0',
              backgroundColor: statusColor,
              borderRadius: '4px',
              color: '#fff',
            }}
          >
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Status: {statusText}
            </Typography>
          </Box>

          <Box textAlign="center">
            <Typography variant="body2" style={{ color: '#616161' }}>
              <strong>Branch:</strong> {build.ref}
            </Typography>
            <Typography variant="body2" style={{ color: '#616161' }}>
              <strong>Commit:</strong> {commitHash}
            </Typography>
          </Box>
        </Box>

        <Box mt="auto">
          {build.web_url ? (
            <Button
              variant="contained"
              color="primary"
              size="medium"
              href={build.web_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textTransform: 'none',
                fontWeight: 'bold',
                boxShadow: 'none',
              }}
            >
              See More on GitLab
            </Button>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No additional build details available.
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
