import React, { useEffect, useState } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { gitLabApiRef } from './GitlabIssuesApi';
import { Progress, Table, TableColumn } from '@backstage/core-components';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Avatar,
  Button,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import dayjs from 'dayjs';

interface GitLabJob {
  id: number;
  name: string;
  created_at: string;
  status: string;
  duration: string;
  web_url: string;
  branch: string;
  commit: string;
  title: string;
  message: string;
  author_name: string;
  author_avatar: string;
  project_path: string; // Assume the API returns this field for filtering
}

export const GitLabJobsComponent = () => {
  const { entity } = useEntity();
  const gitLabApi = useApi(gitLabApiRef);
  const errorApi = useApi(errorApiRef);
  
  const [jobs, setJobs] = useState<GitLabJob[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedJob, setSelectedJob] = useState<GitLabJob | null>(null);

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        // Get projectSlug from entity annotations
        const entitySlug = entity.metadata.annotations?.['gitlab.com/project-slug'];
        if (!entitySlug) {
          // No projectSlug means no jobs to fetch
          setJobs([]);
          setLoading(false);
          return;
        }

        const normalizeSlug = (slug: string) => slug.replace(/\//g, '-').toLowerCase();

        const projectSlug = normalizeSlug(entitySlug);

        // Fetch all jobs for the given group
        const fetchedJobs = await gitLabApi.getJobsByGroup(projectSlug);

        // Filter jobs by matching project_path
        const filteredJobs = fetchedJobs
          .filter((job: any) => job.project_path && job.project_path.toLowerCase() === projectSlug)
          .map((job: any) => ({
            id: job.id,
            name: job.name,
            created_at: dayjs(job.created_at).format('DD/MM/YYYY, HH:mm:ss'),
            status: job.status,
            duration: job.duration ? `${job.duration.toFixed(1)} s` : 'N/A',
            web_url: job.web_url,
            branch: job.ref,
            commit: job.commit.short_id,
            title: job.commit.title,
            message: job.commit.message,
            author_name: job.user.username,
            author_avatar: job.user.avatar_url,
            project_path: job.project_path,
          }));

        setJobs(filteredJobs);
      } catch (error) {
        errorApi.post(new Error(`Failed to fetch jobs for project: ${entity.metadata.name}`));
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [gitLabApi, errorApi, entity]);

  const columns: TableColumn<GitLabJob>[] = [
    { title: 'ID', field: 'id' },
    { title: 'Name', field: 'name' },
    { title: 'Timestamp', field: 'created_at' },
    {
      title: 'Result',
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          <Typography
            style={{
              color:
                rowData.status === 'success'
                  ? 'green'
                  : rowData.status === 'manual'
                  ? 'gray'
                  : 'red',
              fontWeight: 'bold',
            }}
          >
            {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
          </Typography>
        </Box>
      ),
    },
    { title: 'Duration', field: 'duration' },
    {
      title: 'Actions',
      render: (rowData) => (
        <IconButton onClick={() => setSelectedJob(rowData)}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const handleCloseDialog = () => {
    setSelectedJob(null);
  };

  const successfulJobs = jobs.filter((job) => job.status === 'success');
  const avgBuildTime =
    successfulJobs.length > 0
      ? (
          successfulJobs.reduce((acc, job) => acc + parseFloat(job.duration), 0) /
          successfulJobs.length
        ).toFixed(1)
      : 'N/A';

  const projectSlug = entity.metadata.name;

  return (
    <Box>
      {loading ? (
        <Progress />
      ) : (
        <Table
          title={`Jobs: ${projectSlug}`}
          subtitle={
            <Typography variant="subtitle1" gutterBottom>
              Average Build Time For Last {jobs.length} Successful Jobs: {avgBuildTime} s
            </Typography>
          }
          options={{ paging: true, pageSize: 5, search: true, sorting: true }}
          columns={columns}
          data={jobs}
        />
      )}
      {selectedJob && (
        <Dialog open={true} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Job Details: {selectedJob.name}</DialogTitle>
          <DialogContent>
            <Box marginBottom={2}>
              <Typography variant="subtitle1">
                <strong>Branch:</strong> {selectedJob.branch}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Commit:</strong> {selectedJob.commit}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Status:</strong>{' '}
                <span
                  style={{
                    color:
                      selectedJob.status === 'success'
                        ? 'green'
                        : selectedJob.status === 'manual'
                        ? 'gray'
                        : 'orange',
                  }}
                >
                  {selectedJob.status.charAt(0).toUpperCase() + selectedJob.status.slice(1)}
                </span>
              </Typography>
              <Box display="flex" alignItems="center" marginBottom={1}>
                <Typography variant="subtitle1">
                  <strong>Author: </strong>
                </Typography>
                <Avatar
                  src={selectedJob.author_avatar}
                  alt={selectedJob.author_name}
                  style={{ marginRight: 4, marginLeft: 8 }}
                />
                <Typography variant="subtitle1">{selectedJob.author_name}</Typography>
              </Box>
              <Typography variant="subtitle1">
                <strong>Commit Title:</strong> {selectedJob.title}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Commit Message:</strong> {selectedJob.message}
              </Typography>
              <Box marginTop={2} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  href={selectedJob.web_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on GitLab
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};
