import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import { Add, Edit, Close } from '@material-ui/icons';
import {
  useApi,
  identityApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  Avatar,
  InfoCard,
  Progress,
  ResponseErrorPanel,
  Link,
  OverflowTooltip,
} from '@backstage/core-components';
import Pagination from '@material-ui/lab/Pagination';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { stringifyEntityRef, UserEntity, DEFAULT_NAMESPACE } from '@backstage/catalog-model';

const useStyles = makeStyles((theme: Theme) => ({
  memberList: {
    display: 'grid',
    gap: theme.spacing(1.5),
    gridTemplateColumns: `repeat(auto-fit, minmax(auto, ${theme.spacing(34)}px))`,
    paddingTop: theme.spacing(2),
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: -8,
    right: -8,
  },
}));

const MemberComponent = ({ member, onDelete, editMode }: { member: UserEntity; onDelete?: () => void; editMode: boolean }) => {
  const classes = useStyles();
  const displayName = member.spec?.profile?.displayName || member.metadata.name;

  return (
    <Box textAlign="center">
      <Box className={classes.avatarBox}>
        <Avatar displayName={displayName} picture={member.spec?.profile?.picture} />
        {editMode && (
          <IconButton size="small" className={classes.closeButton} onClick={onDelete}>
            <Close fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Typography variant="h6">{displayName}</Typography>
      {member.spec?.profile?.email && (
        <Link to={`mailto:${member.spec.profile.email}`}>
          <OverflowTooltip text={member.spec.profile.email} />
        </Link>
      )}
    </Box>
  );
};

export const GitLabGroupOverviewCard = () => {
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const classes = useStyles();

  const [groups, setGroups] = useState<any[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [groupName, setGroupName] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState<string>('');
  const [accessLevel, setAccessLevel] = useState<number>(30);
  const [allUsers, setAllUsers] = useState<UserEntity[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserEntity[]>([]);
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchGroupMembers = async () => {
    if (!selectedGroupId) return;
    setLoading(true);
    setError(null);
    try {
      const userResponse = await catalogApi.getEntities({
        filter: [{ kind: 'User' }],
        // Include 'kind' in fields or remove 'fields' parameter
        fields: ['kind', 'metadata', 'spec'],
      });
      const backstageUsers = userResponse.items as UserEntity[];
      setAllUsers(backstageUsers);
  
      const { token } = await identityApi.getCredentials();
      const baseUrl = await discoveryApi.getBaseUrl('user-management-backend');
      const gitLabMembersResponse = await fetch(
        `${baseUrl}/groups/${selectedGroupId}/members`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
  
      if (!gitLabMembersResponse.ok) {
        throw new Error(
          `Failed to fetch GitLab members, status ${gitLabMembersResponse.status}`,
        );
      }
  
      const gitLabGroupMembers: Array<{ username?: string; userId: number }> =
        await gitLabMembersResponse.json();
  
      const gitLabUsernames = gitLabGroupMembers
        .map(member => member.username)
        .filter((username): username is string => typeof username === 'string')
        .map(username => username.toLowerCase());
  
      const matchedUsers = backstageUsers.filter(
        user =>
          user.metadata.name &&
          gitLabUsernames.includes(user.metadata.name.toLowerCase()),
      );
  
      setFilteredUsers(matchedUsers);
    } catch (error) {
      console.error('Failed to fetch users:', error);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (selectedGroupId) {
      fetchGroupMembers();
    }
  }, [selectedGroupId]);

  const fetchGroups = async () => {
    try {
      const response = await catalogApi.getEntities({
        filter: [{ kind: 'Component' }],
        fields: ['metadata', 'relations'],
      });

      const groupNamesSet = new Set<string>();
      response.items.forEach((component) => {
        component.relations?.forEach((relation) => {
          if (relation.targetRef.startsWith('group:')) {
            const groupName = relation.targetRef.split('/')[1];
            groupNamesSet.add(groupName);
          }
        });
      });

      const uniqueGroupNames = Array.from(groupNamesSet);
      const baseUrl = await discoveryApi.getBaseUrl('its-gitlab');
      const url = `${baseUrl}/projects`;
      const { token } = await identityApi.getCredentials();

      const projectsResponse = await fetch(url, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });

      if (!projectsResponse.ok) {
        throw new Error(`Failed to fetch GitLab projects, status ${projectsResponse.status}`);
      }

      const projectsData: Array<{ group?: string; groupId?: number }> = await projectsResponse.json();
      const gitlabGroups = uniqueGroupNames
        .map((groupName) => {
          const project = projectsData.find((proj) =>
            proj.group?.toLowerCase() === groupName.toLowerCase()
          );

          if (project?.groupId) {
            return { id: project.groupId, name: groupName };
          }
          return null;
        })
        .filter(Boolean);

      setGroups(gitlabGroups);
      if (gitlabGroups.length > 0) {
        setSelectedGroupId(gitlabGroups[0].id);
        setGroupName(gitlabGroups[0].name);
      }
    } catch (error) {
      console.error('Failed to fetch groups:', error);
    }
  };

  const handleAddMember = async () => {
    if (!selectedUserName) return;
    try {
      const { token } = await identityApi.getCredentials();
      const baseUrl = await discoveryApi.getBaseUrl('user-management-backend');
      await fetch(`${baseUrl}/groups/${selectedGroupId}/members`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName: selectedUserName, accessLevel }),
      });
      setOpen(false);
      setSelectedUserName('');
      // Refresh group members
      fetchGroupMembers();
    } catch (error) {
      console.error('Failed to add member:', error);
    }
  };

  const handleDeleteMember = async (memberName: string) => {
    try {
      const { token } = await identityApi.getCredentials();
      const baseUrl = await discoveryApi.getBaseUrl('user-management-backend');
      await fetch(`${baseUrl}/groups/${selectedGroupId}/members/${memberName}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });
      // Refresh group members
      fetchGroupMembers();
    } catch (error) {
      console.error('Failed to remove member:', error);
    }
  };

  if (loading) return <Progress />;
  if (error) return <ResponseErrorPanel error={error} />;

  const nbPages = Math.ceil((filteredUsers.length || 0) / pageSize);

  return (
    <InfoCard
      title="GitLab Group Manager"
      subheader={
        <>
          <Typography variant="subtitle1">Select Group:</Typography>
          <Select
            value={selectedGroupId ? selectedGroupId.toString() : ''}
            onChange={(event) => {
              const selectedValue = event.target.value;
              const selected = groups.find(group => group.id.toString() === selectedValue);
              setSelectedGroupId(Number(selectedValue));
              setGroupName(selected?.name || '');
            }}
          >
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id.toString()}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </>
      }
      action={
        <Button
          startIcon={<Edit />}
          color="primary"
          variant="contained"
          onClick={() => setEditMode((prev) => !prev)}
        >
          {editMode ? 'Cancel' : 'Edit'}
        </Button>
      }
    >
      <Box className={classes.memberList}>
        {filteredUsers.slice((page - 1) * pageSize, page * pageSize).map((member) => (
          <MemberComponent
            key={stringifyEntityRef(member)}
            member={member}
            editMode={editMode}
            onDelete={() => handleDeleteMember(member.metadata.name)}
          />
        ))}
      </Box>
      {editMode && (
        <Box textAlign="center" mt={2}>
          <Button startIcon={<Add />} color="primary" onClick={() => setOpen(true)}>
            Add User
          </Button>
        </Box>
      )}
      {nbPages > 1 && (
        <Pagination count={nbPages} page={page} onChange={(_, value) => setPage(value)} showFirstButton showLastButton />
      )}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add User to Group</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="User"
            value={selectedUserName}
            onChange={(event) => setSelectedUserName(event.target.value)}
            fullWidth
            style={{ marginBottom: 16 }}
          >
            {allUsers.map((user) => (
              <MenuItem key={user.metadata.name} value={user.metadata.name}>
                {user.metadata.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Access Level"
            value={accessLevel}
            onChange={(event) => setAccessLevel(Number(event.target.value))}
            fullWidth
          >
            <MenuItem value={10}>Guest</MenuItem>
            <MenuItem value={20}>Reporter</MenuItem>
            <MenuItem value={30}>Developer</MenuItem>
            <MenuItem value={40}>Maintainer</MenuItem>
            <MenuItem value={50}>Owner</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddMember} color="primary" disabled={!selectedUserName}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </InfoCard>
  );
};
